import React from "react";
import cardSliderData from "../constants/cardSilderData.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowForward } from "react-icons/io";

const Testimonial = () => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} SampleNextArrow`}
        style={{
          ...style,
          background: "#e3dbd9",

          marginTop: "-320px",
          width: "64px",
          height: "64px",
          borderRadius: "40px",
          border: "2px solid black",
          // textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // color: "black",
        }}
        onClick={onClick}
      ></div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className}  SamplePrevArrow`}
        style={{
          ...style,
          background: "#e3dbd9",
          border: "2px solid black",
          marginTop: "-320px",
          width: "64px",
          height: "64px",
          borderRadius: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {cardSliderData.testimonials.map((item, index) => (
        <div key={`testimonial-${index}`}>
          <div
            style={{
              margin: "25px",
              border: "1px solid #ececec",
              borderRadius: "5px",
            }}
          >
            <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
              <div className="bg-[#fff] w-full  h-full  px-2 md:px-4 py-2 rounded-lg ">
                <div className="flex items-center gap-2 mt-8 ">
                  <img
                    className="w-8 h-8"
                    src="https://curriculo.gumlet.io/Vector+(7).png"
                    alt="This is user"
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.company,

                    }}
                    className=" roboto font-bold text-2xl text-[#475569] "
                  ></p>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                  className="pt-6 text-xl font-normal truncate-multiline Open "
                ></p>
                <div className="flex items-center gap-2 pt-10">
                  <img
                    className="w-16 h-16"
                    src={item.image}
                    alt="This is user"
                  />
                  <div className="roboto">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.name,
                      }}
                      className="text-lg font-normal"
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.profession,
                      }}
                      className="text-base font-normal text-[#475569]"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Testimonial;
