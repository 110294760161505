import React from "react";
import { Link } from "react-router-dom";
import WaitList from "./WaitList";
import FooterWaitList from "./FooterWaitList";
import { FaXTwitter, FaReddit } from "react-icons/fa6";
import { FaInstagram, FaLinkedin, FaFacebook, FaDiscord,  } from "react-icons/fa";
import { IconContext } from "react-icons";

import footer from "../constants/footer.json";
import linkData from "../constants/linkData.json";
// import moment from "react-moment";
import moment from "moment";
import "moment-timezone";
const Footer = () => {
  // const currentYear = moment().format("YYYY");
  const currentYear = moment().format("YYYY");
  return (
    <>
      <div className="bg-black  text-[#fff] mt-[450px] sm:mt-96  md:mt-48 py-14 px-10 ">
        <div></div>
        {/* <div className="!text-white"> */}
        <FooterWaitList />
        {/* </div> */}
      </div>
      <div className="flex flex-col items-center justify-between h-20 px-8 mt-3 text-base font-normal md:flex-row md:flex roboto md:mt-0 ">
        <div className="flex items-center gap-2 list-none">
          <Link to={"/"}>
            <div>
              {/* <h1 className="text-3xl font-normal md:text-5xl roboto">LOGO</h1> */}
              {/* <img src="/img/Logo.png" alt="" /> */}
              <img src="https://curriculo.gumlet.io/logo_full.svg" alt="Logo" />
            </div>
          </Link>
          <p className="hidden text-xl md:block">
            {currentYear + footer.text}
            {/* © 2024 Curriculo. All rights reserved. */}
          </p>
        </div>

        <IconContext.Provider value={{ color: "#7c59c8", className: "global-class-name" }}>

          <div className="flex items-center gap-5 text-2xl cursor-pointer md:text-3xl">
            <Link to={linkData.meta}>
              <FaFacebook />
            </Link>
            <Link to={linkData.linkedin}>
              <FaLinkedin />
            </Link>
            <Link to={linkData.instagram}>
              <FaInstagram />
            </Link>
            <Link to={linkData.Reddit}>
              <FaReddit />
            </Link>
            <Link to={linkData.discord}>
              <FaDiscord />
            </Link>
            <Link to={linkData.x}>
              <FaXTwitter />
            </Link>
          </div>
        </IconContext.Provider>

        <div className="flex flex-col items-center justify-center gap-1 mt-2 list-none md:flex md:flex-row md:gap-5 md:mt-0 ">
          <Link to={"/terms"}>
            <li>{footer.footerTerms}</li>
          </Link>
          <Link to={"/privacy"}>
            <li>{footer.footerPrivacy}</li>
          </Link>
          <Link to={"/support"}>
            <li>{footer.footerSupport}</li>
          </Link>
          <p className="block md:hidden">{currentYear + footer.text}</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
