import React from "react";
import "react-toastify/dist/ReactToastify.css";
import WaitList from "./WaitList";

const FooterWaitList = () => {
  return (
    <div>
      <WaitList isBlackColor={true} />
    </div>
  );
};

export default FooterWaitList;
